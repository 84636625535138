/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "api19a88d9c",
            "endpoint": "https://mf5dwl988h.execute-api.us-east-2.amazonaws.com/sandbox",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gpjyygnx7bcmlbcfsdv3g5br3a.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wjebckmmdzddbi4r5zpcvpb6pi",
    "aws_cognito_identity_pool_id": "us-east-2:ba10bfcb-9308-49b2-8479-2b95342a1766",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_BFj3PCatF",
    "aws_user_pools_web_client_id": "7jaca24nh3bm0hf0bm9d9ue47s",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "idenciaamplify-sandbox-hostingbucket-sandbox",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3b6inxfc9010t.cloudfront.net",
    "aws_user_files_s3_bucket": "idenciasandbox59250-sandbox",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
